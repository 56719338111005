import './ContainsKeyword.scss';
import { useTranslation } from 'react-i18next';

import { Typography } from '@mui/material';
import CustomTooltip from '../CustomTooltip/CustomTooltip';

interface Props {
  value?: string;
}

const ContainsKeyword = ({ value }: Props) => {
  const { t } = useTranslation();

  if (value === undefined) return false;

  return (
    <CustomTooltip content={t(`tooltip.contains_keyword_${value.toString()}`)}>
      <div className="contains-keyword">
        <div className={`border ${value}`}>
          <Typography fontWeight={700} className={value.toString()}>
            {t(`contains_keyword_${value.toString()}`)}
          </Typography>
        </div>
      </div>
    </CustomTooltip>
  );
};

export default ContainsKeyword;
