import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import './styles.css';

import React from 'react';
import { useTranslation } from 'react-i18next';
import { subMonths, subWeeks, isSameDay, format } from 'date-fns';
import {
  Range,
  RangeKeyDict,
  DateRangePicker,
  createStaticRanges
} from 'react-date-range';

import { DEFAULT_VALUE_RANGE, FORMAT_DATE_RANGE } from '../../utils/Constants';

import Menu from '../Menu/Menu';
import { Button } from '@mui/material';

interface Props<T = Range> {
  value?: T;
  onChangeValue: (value: T) => void;
}

const DateRange: React.FC<Props> = ({ onChangeValue }) => {
  const { t } = useTranslation();

  const STATIC_RANGES = createStaticRanges([
    {
      label: t('last_week'),
      range: () => ({
        startDate: subWeeks(new Date(), 1),
        endDate: new Date(),
      }),
      isSelected(range: Range) {
        const definedRange = this.range();
        return (
          range.startDate &&
          range.endDate &&
          definedRange.startDate &&
          definedRange.endDate &&
          isSameDay(range.startDate, definedRange.startDate) &&
          isSameDay(range.endDate, definedRange.endDate)
        );
      },
    },
    {
      label: t('last_month'),
      range: () => ({
        startDate: subMonths(new Date(), 1),
        endDate: new Date(),
      }),
      isSelected(range: Range) {
        const definedRange = this.range();
        return (
          range.startDate &&
          range.endDate &&
          definedRange.startDate &&
          definedRange.endDate &&
          isSameDay(range.startDate, definedRange.startDate) &&
          isSameDay(range.endDate, definedRange.endDate)
        );
      },
    },
    {
      label: t('last_3_months'),
      range: () => ({
        startDate: subMonths(new Date(), 3),
        endDate: new Date(),
      }),
      isSelected(range: Range) {
        const definedRange = this.range();
        return (
          range.startDate &&
          range.endDate &&
          definedRange.startDate &&
          definedRange.endDate &&
          isSameDay(range.startDate, definedRange.startDate) &&
          isSameDay(range.endDate, definedRange.endDate)
        );
      },
    },
    {
      label: t('custom_range'),
      isSelected: () => false,
      range: () => ({
        endDate: new Date(),
        startDate: new Date(),
      }),
    }
  ]);

  const [showCalendar, setShowCalendar] = React.useState<boolean>(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [dateRanges, setDateRanges] = React.useState<Range[]>([DEFAULT_VALUE_RANGE]);

  const open = Boolean(anchorEl);

  const handleClose = () => setAnchorEl(null);
  const handleClear = () => onChangeValue(DEFAULT_VALUE_RANGE);
  const toggleRangePicker = () => setShowCalendar((prev) => !prev);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => setAnchorEl(event.currentTarget);

  const handleApplyDate = () => {
    onChangeValue(dateRanges[0]);
    toggleRangePicker();
    handleClose();
  };

  const handleDefautlDate = () => {
    setDateRanges([DEFAULT_VALUE_RANGE]);
    toggleRangePicker();
    handleClose();
  };

  const handleValidateDate = (date: RangeKeyDict) => {
    const selection = date.selection;
    if (!selection.startDate || !selection.endDate) {
      return;
    }
    const startDate = selection.startDate;
    const endDate = selection.endDate;

    const existingRange = STATIC_RANGES.find(r => {
      const definedRange = r.range();
      return (
        definedRange.startDate &&
        definedRange.endDate &&
        isSameDay(startDate, definedRange.startDate) &&
        isSameDay(endDate, definedRange.endDate)
      );
    });

    setDateRanges([selection]);
    onChangeValue({
      endDate: format(endDate, FORMAT_DATE_RANGE) as unknown as Date,
      startDate: format(startDate, FORMAT_DATE_RANGE) as unknown as Date,
    });

    if (existingRange && existingRange.label !== t('custom_range')) {
      if (!showCalendar) {
        handleClose();
      }
    } else {
      setShowCalendar(true);
    }
  };

  const defaultValue = React.useMemo(() => {
    return t('date_range.default_format', {
      startDate: dateRanges[0].startDate
        ? format(dateRanges[0].startDate, 'dd/MM/yyyy')
        : '',
      endDate: dateRanges[0].endDate
        ? format(dateRanges[0].endDate, 'dd/MM/yyyy')
        : '',
    });
  }, [dateRanges, t]);

  return (
    <Menu
      open={open}
      anchorEl={anchorEl}
      onClear={handleClear}
      onClick={handleClick}
      onClose={handleClose}
      defaultValue={defaultValue}>
      <DateRangePicker
        months={2}
        ranges={dateRanges}
        inputRanges={[]}
        maxDate={new Date()}
        direction="horizontal"
        showDateDisplay={false}
        showMonthAndYearPickers={false}
        onChange={(item) => handleValidateDate(item)}
        className={showCalendar ? '' : 'rdrHide'}
        staticRanges={STATIC_RANGES}
      />
      {showCalendar && (
        <div className="flex gap-2 my-3 w-full justify-end">
          <Button
            color="error"
            variant="contained"
            className="capitalize"
            onClick={handleDefautlDate}>
            {t('cancel')}
          </Button>
          <Button variant="contained" className="capitalize" onClick={handleApplyDate}>
            {t('apply')}
          </Button>
        </div>
      )}
    </Menu>
  );
};

export default DateRange;