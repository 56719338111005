import { createBrowserRouter, Navigate, RouterProvider } from 'react-router-dom';

import { ROUTES } from './utils/Constants';

// MAINS
import { Home } from './pages';
import { Error404 } from './pages/404';
import ErrorPage from './components/Error/ErrorPage'
import Splash from './components/Splash';
import BuyPlanPage from './pages/buy-plan';;
import SubscriptionThanksPage from './pages/thanks';
import PlanGuard from './components/PlanGuard/PlanGuard';
import ScrollToTop from './components/ScrollToTop/ScrollToTop';
import { PageContainer } from './components/Essential/PageContainer';
import PlanGuestGuard from './components/PlanGuestGuard/PlanGuestGuard';

// PROJECTS-PAGE
import { ProjectsManagePage } from './pages/projects/manage';
import { ProjectsThinContentPage } from './pages/projects/thin_content';
import { ProjectsCannibalizationPage } from './pages/projects/cannibalization';
import { ProjectsOpportunityKeywordsPage } from './pages/projects/opportunity_keywords';
import { ProjectsPerformance } from './pages/projects/ProjectsPerformance';
import { ProjectDetail } from './pages/projects/ProjectDetail';

// KEYWORD-PAGE
import { Trends } from './pages/keywords/trends';
import { KeywordsIdeasPage } from './pages/keywords/ideas';
import { KeywordsAnalysis } from './pages/keywords/analysis';
import { BatchAnalysisPage } from './pages/keywords/batch_analysis';

// DOMAIN-PAGE
import { DomainView } from './pages/domain/view';
import { Comparator } from './pages/domain/contentgap';
import { BacklinksPage } from './pages/domain/backlinks';
import { Competitors } from './pages/domain/competitors';
import { KeywordsDomainsPage } from './pages/domain/positions';
import { BulkAnalysisPage } from './pages/domain/bulk_analysis';

// IDEAS DE NICHO
import { NicheIdeasPage } from './pages/keywords/nicheIdeas';
import { DomainsIdeasPage } from './pages/domain/ideas';

// ACCOUTS-PAGE
import { AccountBillingsPage } from './pages/account/billings';
import { AccountConnectionsPage } from './pages/account/connections';
import { AccountMessingAroundPage } from './pages/account/messing-around';
import { AccountContainer } from './components/Essential/AccountContainer';

const router = createBrowserRouter([
  {
    path: '/',
    element: (
      <ScrollToTop>
        <PlanGuard>
          <PageContainer>
            <Home />
          </PageContainer>
        </PlanGuard>
      </ScrollToTop>
    ),
    errorElement: <Error404 />
  },{
    path: '/error',
    element: <ErrorPage />
  },
  {
    path: ROUTES.BUY_PLAN_USER,
    element: <BuyPlanPage />
  },
  {
    path: ROUTES.THANKS,
    element: (
        <SubscriptionThanksPage />
    )
  },
  {
    path: ROUTES.ACCOUNT,
    element: (
      <ScrollToTop>
        <PlanGuard>
          <AccountContainer />
        </PlanGuard>
      </ScrollToTop>
    ),
    children: [
      {
        index: true,
        element: <Navigate replace to={ROUTES.ACCOUNT_CONNECTIONS} />
      },
      {
        path: 'connections/',
        element: <AccountConnectionsPage />
      },
      {
        path: 'billings/',
        element: <AccountBillingsPage />
      },
      {
        path: 'messing-around/',
        element: <AccountMessingAroundPage />
      }
    ]
  },
  {
    path: ROUTES.PROJECTS,
    element: (
      <ScrollToTop>
        <PlanGuard>
          <PageContainer />
        </PlanGuard>
      </ScrollToTop>
    ),
    children: [
      {
        index: true,
        element: <Navigate replace to={ROUTES.PROJECTS_MANAGE} />
      },
      {
        path: 'manage/',
        element: <ProjectsManagePage />
      },
      {
        path: 'cannibalization/',
        element: <ProjectsCannibalizationPage />
      },
      {
        path: 'thin-content/',
        element: <ProjectsThinContentPage />
      },
      {
        path: 'opportunity-keywords/',
        element: <ProjectsOpportunityKeywordsPage />
      },
      {
        path: 'performance/',
        element: <ProjectsPerformance />
      },
      {
        path: 'performance/detail/:domain',
        element: <ProjectDetail />
      }
    ]
  },
  {
    path: ROUTES.KEYWORDS,
    element: (
      <ScrollToTop>
        <PlanGuard>
          <PageContainer />
        </PlanGuard>
      </ScrollToTop>
    ),
    children: [
      {
        index: true,
        element: <Navigate replace to={ROUTES.EXPLORE} />
      },
      {
        index: true,
        path: 'explorer/',
        element: <KeywordsAnalysis />
      },
      {
        path: 'ideas/',
        element: <KeywordsIdeasPage />
      },
      {
        path: 'bulk-analysis/',
        element: <BatchAnalysisPage />
      },
      {
        path: 'trends/',
        element: <Trends />
      }
    ]
  },
  {
    path: ROUTES.DOMAINS,
    element: (
      <ScrollToTop>
        <PlanGuard>
          <PageContainer />
        </PlanGuard>
      </ScrollToTop>
    ),
    children: [
      {
        index: true,
        element: <Navigate replace to={ROUTES.DOMAIN_VIEW} />
      },
      {
        index: true,
        path: 'view/',
        element: <DomainView />
      },
      {
        path: 'bulk-analysis/',
        element: <BulkAnalysisPage />
      },
      {
        path: 'keywords/',
        element: <KeywordsDomainsPage />
      },
      {
        path: 'competitors/',
        element: <Competitors />
      },
      {
        path: 'backlinks/',
        element: <BacklinksPage />
      },
      {
        path: 'content-gap/',
        element: <Comparator />
      }
    ]
  },
  {
    path: ROUTES.NICHE_IDEAS,
    element: (
      <ScrollToTop>
        <PlanGuard>
          <PageContainer />
        </PlanGuard>
      </ScrollToTop>
    ),
    children: [
      {
        path: 'keywords/',
        element: <NicheIdeasPage />
      },
      {
        path: 'domains/',
        element: <DomainsIdeasPage />
      }
    ]
  }
]);

export default function Routes() {
  return <RouterProvider router={router} fallbackElement={<Splash />} />;
}