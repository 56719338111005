import './TableComponent.scss';

import { ErrorBoundary } from 'react-error-boundary';
import { useTranslation } from 'react-i18next';

import { Box, LinearProgress, Skeleton } from '@mui/material';
import { DataGridPremium, GridRenderCellParams } from '@mui/x-data-grid-premium';
import CustomErrorBoundary from '../Error/CustomErrorBoundary';


const processColumns = (columns) => {
  return columns.map((column) => {
    const customRenderer = column.renderCell || ((params) => String(params.value));
    return {
      ...column,
      renderCell: (params: GridRenderCellParams) => {
        if (params.formattedValue === null) {
          return '—';
        }
        const cellValue = customRenderer(params);
        return <div>{cellValue}</div>;
      }
    };
  });
};

const showSkeleton = () => {
  return (
    <Box px={2} py={1}>
      {[...Array(10)].map((_, index) => (
        <Skeleton key={index} animation="wave" height={40} sx={{ mb: 1 }} />
      ))}
    </Box>
  );
};

const TableComponent = ({
                          rows,
                          columns,
                          slots,
                          loading,
                          blurTitle,
                          overlayPercentages,
                          refreshData,
                          ...props
                        }) => {
  const { t } = useTranslation();

  if (loading) {
    return showSkeleton();
  }

  if (!rows || !Array.isArray(rows)) {
    return showSkeleton();
  }

  // Automatically add IDs to rows only if they don't have one
  rows = rows.map((row, index) => ({
    ...row,
    id: row.id ?? index + 1
  }));


  return (
    <ErrorBoundary FallbackComponent={CustomErrorBoundary}>
      <DataGridPremium
        className={'dataGrid'}
        autoHeight
        initialState={{
          pagination: { paginationModel: { pageSize: 10 } }
        }}
        rows={rows}
        columns={processColumns(columns)}
        loading={loading}
        slots={{
          loadingOverlay: LinearProgress,
          ...slots
        }}
        localeText={{
          noRowsLabel: t('no_rows'),
          MuiTablePagination: {
            labelRowsPerPage: `${t('row_per_page')}:`
          }
        }}
        getRowClassName={() => 'paxton-table--row'}
        getCellClassName={(params) => 'center-cell'}
        ignoreDiacritics={true}
        disableColumnMenu={true}
        {...props}

        pagination
        paginationMode={props.paginationMode || 'server'}
        pageSizeOptions={[10, 25, 50, 100]}
        sortingMode={props.sortingMode || 'server'}
        sortingOrder={['asc', 'desc']}
        hideFooter={props.hideFooter === true}
      />
    </ErrorBoundary>
  );
};

export default TableComponent;
