import '../../styles/connections.css';
import React from 'react';
import Swal from 'sweetalert2';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import { useAccessToken } from '../../hooks/useAccessToken';
import { SweetAlertIcon } from '../../utils/Constants';
import { useColorModeContext } from '../../context/ColorModeContext';
import { useUsersConnections } from '../../hooks/useUsersConnections';
import { classNames, generateIcon } from '../../utils/Utils';
import { COLUMNS_ACCOUNT_CONNECTIONS } from '../../utils/TableColumnsConstants';

import { Stack, Button, Typography, ButtonGroup, Tooltip, CircularProgress } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import TableComponent from '../../components/TableComponent';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import AddchartIcon from '@mui/icons-material/Addchart';

interface IAccount {
  id: string;
  name: string;
  email: string;
  profile_url: string;
}

export const AccountConnectionsPage = () => {
  const { t } = useTranslation();
  const token = useAccessToken();
  const { mode } = useColorModeContext();
  const [searchParams, setSearchParams] = useSearchParams();

  const {
    error,
    loading,
    connectedAccounts,
    getAccounts,
    deleteAccounts,
    redirectAccount,
    fetchAccountToDatabase
  } = useUsersConnections();

  const handleDeleteAccountSwal = (account: IAccount) => {
    Swal.fire({
      title: t('are_you_delete_account'),
      icon: SweetAlertIcon.WARNING,
      showCloseButton: true,
      showCancelButton: true,
      cancelButtonText: t('cancel'),
      confirmButtonText: t('delete'),
      confirmButtonColor: '#de3030'
    }).then(async (result) => {
      if (result.isConfirmed) await handleDeleteAccount(account.id);
    });
  };

  const handleDeleteAccount = async (id: string) => {
    try {
      const res = await deleteAccounts(id);
      if (res) {
        Swal.fire({
          title: t('Yes...'),
          icon: SweetAlertIcon.SUCCESS,
          text: t('your_account_has_been_successfully_deleted')
        });
        getAccounts();
      } else {
        Swal.fire({
          title: 'Oh...',
          icon: SweetAlertIcon.INFO,
          text: t('error_occurred_delete_account')
        });
      }
    } catch (error) {
      Swal.fire({ icon: SweetAlertIcon.ERROR, title: 'Oppss...', text: error });
    }
  };

  const handleRedirectAccount = async () => {
    Swal.fire({
      title: t('connect_new_account'),
      text: t('you_are_going_to_be_redirected_to_google'),
      icon: SweetAlertIcon.INFO,
      showCloseButton: true,
      showCancelButton: true,
      cancelButtonText: t('cancel'),
      confirmButtonText: t('go_to_google')
    }).then(async (result) => {
      if (result.isConfirmed) await redirectAccount();
    });
  };

  const handleAddAccocuntToDb = async (code: string) => {
    try {
      const res = await fetchAccountToDatabase(code);
      if (res) {
        Swal.fire({
          title: t('Yes...'),
          icon: SweetAlertIcon.SUCCESS,
          text: t('your_account_has_been_successfully_connected')
        });
        await getAccounts();
      } else {
        Swal.fire({
          title: 'Oh...',
          icon: SweetAlertIcon.INFO,
          text: t('error_occurred_connect_account')
        });
      }
    } catch (error) {
      Swal.fire({
        title: 'Oh...',
        icon: SweetAlertIcon.ERROR,
        text: t('error_occurred_connect_account')
      });
    } finally {
      searchParams.delete('code');
      setSearchParams(searchParams);
    }
  };

  const COLUMNS_CONNECTIONS: GridColDef[] = React.useMemo(
    () => [
      ...COLUMNS_ACCOUNT_CONNECTIONS(t),
      {
        field: 'delete',
        headerName: t('delete'),
        description: t('delete'),
        align: 'center',
        pinnable: false,
        headerAlign: 'center',
        renderCell: (params) => (
          <Button color="error" onClick={() => handleDeleteAccountSwal(params.row)}>
            {generateIcon('Delete')}
          </Button>
        )
      }
    ],
    []
  );

  React.useEffect(() => {
    if (token) getAccounts();
  }, [token, getAccounts]);

  React.useEffect(() => {
    const code = searchParams.get('code');
    if (code && token) handleAddAccocuntToDb(code);
  }, [searchParams, token]);

  return (
    <Stack>
      <Stack className="connections-header connections-stack">
        <h1>{t('connections')}</h1>
        <ButtonGroup
          variant="contained"
          sx={{ border: 'none' }}
          aria-label="Button group with a nested menu">
          <Tooltip title={t('tooltip.addAccount')}>
            <Button className="btn-manage" onClick={handleRedirectAccount}>
              <PersonAddIcon />
              {t('account')}
            </Button>
          </Tooltip>
        </ButtonGroup>
      </Stack>
      <TableComponent
        hideFooter
        loading={loading}
        rows={connectedAccounts}
        disableRowSelectionOnClick
        columns={COLUMNS_CONNECTIONS}
        className={classNames('table-connections', mode)}
        slots={{
          noResultsOverlay: () => (
            <Stack className="connections-info">
              <Typography>{error?.message}</Typography>
            </Stack>
          ),
          noRowsOverlay: () => (
            <Stack className="connections-info">
              {error ? (
                <Typography>{error?.message}</Typography>
              ) : (
                <Typography>{t('no_accounts_connected')}</Typography>
              )}
            </Stack>
          )
        }}
      />
    </Stack>
  );
};
