import '../CountrySelector/CountrySelector.css';
import { useEffect, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import * as CONSTANTS from '../../utils/Constants';
import { classNames, urlCountryFlag } from '../../utils/Utils';
import countries, { CountryType } from '../../utils/Countries';
import { useColorModeContext } from '../../context/ColorModeContext';

import { Box, List, Popover, ListItem, Typography } from '@mui/material';
import SearchSimple from '../SearchSimple/SearchSimple';

const defaultCountry = {
  country: CONSTANTS.FIRST_COUNTRY,
  location: CONSTANTS.LOCATION_CODE_ES,
  currency: CONSTANTS.DEFAULT_CURRENCY as any,
};

interface Props {
  only?: boolean;
  onChange?: (value: Omit<CountryType, 'label'>) => void;
  initialLocation?: number;
}

const CountryWithCodeSelector: React.FC<Props> = ({ only, onChange, initialLocation }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState<boolean>(false);
  const [search, setSearch] = useState<string>('');
  const [country, setCountry] = useState<Omit<CountryType, 'label'>>(defaultCountry);

  const { t } = useTranslation();
  const { mode } = useColorModeContext();

  const handleinputchange = (value) => {
    setCountry(value);
    if (onChange) {
      onChange(value);
    }
    handleClose();
  };

  const id = open ? 'simple-popover' : undefined;

  const handleClick = (event) => {
    setOpen((prev) => !prev);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(false);
    setAnchorEl(null);
    setSearch('');
  };

  const countriesMemoized = useMemo(() => {
    if (search) {
      return countries.filter((item) => item.label.toLowerCase().includes(search.toLowerCase()));
    }
    return countries;
  }, [search]);

  useEffect(() => {
    if (initialLocation !== undefined && initialLocation !== country.location) {
      const newCountry = countries.find((item) => item.location === initialLocation);
      if (newCountry) {
        const defaultCountry = {
          location: newCountry.location,
          country: newCountry.country,
          currency: newCountry.currency,
        };
        setCountry(defaultCountry);
      }
    }
  }, [initialLocation, country.location]);

  return (
    <div className="country-selector-wrap">
      <button
        onClick={handleClick}
        className={classNames('country-selector', only ? 'only' : '', mode)}>
        <img loading="lazy" src={urlCountryFlag(country.country)} alt={country.country} />
      </button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        sx={{
          '>div~div.MuiPaper-elevation': {
            maxWidth: 230,
            width: '100%',
            borderRadius: 3,
            borderWidth: 1,
            borderStyle: 'solid',
            borderColor: (theme) =>
              theme.palette.mode !== CONSTANTS.COLOR_MODE_DARK
                ? 'rgba(0, 0, 0, 0.12)'
                : '#FFFFFF10',
            boxShadow: '0px 0px 10px -1px rgba(0,0,0,0.5)',
          },
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}>
        <Box
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === CONSTANTS.COLOR_MODE_DARK ? '#1f1f1f' : '#FFF',
            position: 'sticky',
            top: 0,
            zIndex: 10,
          }}>
          <SearchSimple
            onChange={setSearch}
            style={{ borderRadius: 0, border: '0', borderBottom: '1px solid #1f1f1f' }}
          />
        </Box>
        <Box sx={{ px: 2, maxHeight: 200 }}>
          <List>
            {countriesMemoized.map((option) => (
              <ListItem
                key={option.location}
                sx={{ gap: 1, alignItems: 'center', borderRadius: 2 }}
                onClick={() => handleinputchange(option)}>
                <img
                  width="20"
                  loading="lazy"
                  alt={option.country}
                  src={urlCountryFlag(option.country)}
                />
                <Typography>{t(`flag.label.${option.label}`)}</Typography>
              </ListItem>
            ))}
          </List>
        </Box>
      </Popover>
    </div>
  );
};

export default CountryWithCodeSelector;