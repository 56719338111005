import './ProjectProperty.scss'
import { Grid, MenuItem, IconButton, Tooltip } from '@mui/material';
import { Visibility as VisibilityIcon, VisibilityOff as VisibilityOffIcon } from '@mui/icons-material';
import Selector from '../Selector/Selector';
import DateRangeSelector from '../Selectors/DateRangeSelector/DateRangeSelector';
import ToggleCustomDots from '../Charts/SearchConsoleChart/ToggleCustomDots/ToggleCustomDots';
import GlobalMetricToggle from '../Charts/SearchConsoleChart/GlobalMetricToggle/GlobalMetricToggle';

const ProjectSelection = ({
                            dataProject,
                            selectedProject,
                            handleProjectChange,
                            dateRange,
                            setDateRange,
                            blurTitle,
                            toggleBlur,
                            showCustomDots,
                            handleToggleCustomDots,
                            t,
                            projectUrl,
                            metrics,
                            handleGlobalMetricToggle
                          }) => {
  const initialProject = dataProject.find(project => project.property === projectUrl) || null;

  return (
    <Grid container spacing={2} alignItems="center" justifyContent={{ xs: 'center', md: 'flex-end' }}>
      <Grid item xs={12} container spacing={2} justifyContent={{ xs: 'center', lg: 'flex-end' }} alignItems="center" flexWrap="wrap">
        <Grid item xs={'auto'} order={{ xs: 1, md: 4 }}>
          <ToggleCustomDots active={showCustomDots} onClick={handleToggleCustomDots} />
        </Grid>
        <Grid item xs={'auto'} order={{ xs: 2, md: 1 }}>
          <GlobalMetricToggle metrics={metrics} onToggle={handleGlobalMetricToggle} />
        </Grid>
        <Grid item xs={'auto'} order={{ xs: 3, md: 5 }}>
          <Tooltip title={blurTitle ? t('domains_hidden') : t('domains_visible')}>
            <IconButton sx={{ padding: 0 }} onClick={toggleBlur} aria-label="toggle blur">
              {blurTitle ? <VisibilityOffIcon /> : <VisibilityIcon />}
            </IconButton>
          </Tooltip>
        </Grid>
        <Grid item xs={12} sm={'auto'} order={{ xs: 4, md: 2 }}>
          <Selector
            value={selectedProject ? JSON.stringify(selectedProject) : initialProject ? JSON.stringify(initialProject) : ''}
            label={t('Select Project')}
            onChange={handleProjectChange}
            blurContent={blurTitle}>
            {dataProject.length > 0 ? (
              dataProject.map((project) => (
                <MenuItem key={project.id} value={JSON.stringify(project)}>
                  {project.property}
                </MenuItem>
              ))
            ) : (
              <MenuItem disabled>{t('No projects available')}</MenuItem>
            )}
          </Selector>
        </Grid>
        <Grid item xs={12} sm={'auto'} order={{ xs: 5, md: 3 }}>
          <DateRangeSelector onChange={setDateRange} defaultStartDate={dateRange.startDate} defaultEndDate={dateRange.endDate} />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ProjectSelection;