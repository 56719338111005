import axios from 'axios';
import i18n from '../i18n';
import Swal from 'sweetalert2';
import { getBaseUrl } from '../utils/Utils';
import { SweetAlertIcon } from '../utils/Constants';

const apiClient = axios.create({
  baseURL: getBaseUrl(),
  headers: {
    'Content-Type': 'application/json'
  },
  timeout: 30000 // 30 seconds
});

apiClient.interceptors.response.use(
  (response) => response,
  (error) => {
    const status = error.response ? error.response.status : null;
    const reponseData = error.response ? error.response.data : null;
    const configUrl = error.config.url ? error.config.url : null;
    const hasDomain = configUrl?.includes('/domains/v1/');
    const hasKeywords = configUrl?.includes('/keywords/v1/');
    const hasProjects = configUrl?.includes('/projects/v1/');

    console.error('error response', error.response);

    if (status === 400) {
      if (reponseData === 'Invalid payload' && hasDomain) {
        Swal.fire({
          title: 'Oppss...',
          icon: SweetAlertIcon.ERROR,
          text: i18n.t('error.api.invalid_domain')
        });
      }
    } else if (status === 401) {
      Swal.fire({
        title: 'Oppss...',
        icon: SweetAlertIcon.ERROR,
        text: i18n.t('error.api.unauthorized')
      });
    } else if (status === 403) {
      if (reponseData === 'Limit exceeded' && (hasDomain || hasKeywords || hasProjects)) {
        Swal.fire({
          title: 'Oppss...',
          icon: SweetAlertIcon.ERROR,
          text: i18n.t('error.api.limit_exceeded')
        });
      }
    } else if (status === 404) {
      // TODO: Handle not found errors
    } else if (status === 418) {
      Swal.fire({ icon: SweetAlertIcon.ERROR, title: 'Oppss...', text: i18n.t(reponseData) });
      return Promise.reject(error);
    }

    return Promise.reject(error);
  }
);

export default apiClient;
