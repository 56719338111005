import React from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { List, ListItem, Collapse, ListItemText } from '@mui/material';
import { classNames } from '../../utils/Utils';
import { useColorModeContext } from '../../context/ColorModeContext';
import { ROUTES_DOMAINS, ROUTES_KEYWORDS, ROUTES_PROJECTS, ROUTES_NICHE_IDEAS } from '../../utils/routes';
import { SubRoute } from './SubRouteMain';

export function Sidebar() {
  const { t } = useTranslation();
  const location = useLocation();
  const { open, mode } = useColorModeContext();

  const exactRoute = (path) => location.pathname.includes(path);

  // TOOLTIPS TEMPORALES EN SIDEBAR SE DEBE TRASLADAR AL JSON DE TRASLATION
  const tooltips = {
    projects: {
      manage: t('Agrega/elimina proyectos de tus cuentas de google.'),
      cannibalization: t('Descrubre que URLS son las que están canibalizando por una misma palabra clave en tus proyectos.'),
      'thin-content': t(' Descubre el contenido de poco valor de tu proyecto para realizar curaciones de contenido.'),
      'opportunity-keywords': t('Descubre las palabras clave de oportunidad incluidas y no incluidad en cada uno de tus proyectos.'),
      performance: t('Revisa de una forma rápida y sencilla datos importantes de todos tus proyecto como clicks, impresiones, CTR o incluso posición media.')
    },
    keywords: {
      explorer: t('Visión general de una palabra clave, donde el sistema mostrará métricas importantes como el CPC, volumen, dificultad, etc.'),
      ideas: t('De una palabra clave podrás descubrir palabras clave long tails o incluso otras palabras clave relacionadas.'),
      'batch-analysis': t('Coloca varias palabras clave y el sistema te mostrará las métricas más importantes de esa palabra clave.'), 
    },
    domains: {
      view: t('Coloca un dominio e investiga el tráfico, sus palabras clave y sus competidores entre otras métricas importantes.'),
      'bulk-analysis': t('Coloca varios dominios y el sistema te mostrará cuales son las métricas más importantes de cada uno de ellos.'), 
      positions: t('Coloca un dominio y descubre cuales son las palabras clave por las cuales posiciona.'),
      competitors: t('Descubre cuales son los competidores principales de un dominio concreto.'),
      backlinks: t('Saca los enlaces más importantes de cada dominio que coloques en el buscador.'),
      'content-gap': t('Compara tu página web entre tu competidor principal. Saca las palabras clave que tu competencia posiciona y que tu no tienes en tu web.'),
    },
    nicheIdeas: {
      'niche-keywords': t('Con palabra o sin palabra, con filtro o sin filtro, este sistema te mostrará ideas de palabras clave que podrás utilizar para hacer tus nichos.'),
      'niche-domains': t('Este módulo te mostrará dominios de una simple tirada. Coloca filtros, palabras, y el sistema se encargará de ofrecerte dominios de la competencia para analizar.'),
    },
  };

  const getTooltipContent = (category, itemName) => {
    const baseItemName = itemName?.split('.').pop();
    return tooltips[category]?.[baseItemName] || '';
  };

  return (
    <div className={classNames('sidebar', open ? '' : 'sidebar-closed', mode)}>
      <List className="ListSidebar">
        <ListItem>
          <ListItemText primary={t('routes.projects')} />
        </ListItem>
        <Collapse in>
          <List component="div" disablePadding>
            {ROUTES_PROJECTS.map((item) => (
              <SubRoute
                key={item.key}
                path={item.path}
                plans={item.plans}
                subRouteName={t(item.name)}
                tooltipContent={getTooltipContent('projects', item.name)}
                selected={exactRoute(item.path)}
                nextAvailablePlan={item?.nextAvailablePlan}
              />
            ))}
          </List>
        </Collapse>

        <ListItem>
          <ListItemText primary={t('routes.keywords')} />
        </ListItem>
        <Collapse in>
          <List component="div" disablePadding>
            {ROUTES_KEYWORDS.map((item) => (
              <SubRoute
                key={item.key}
                path={item.path}
                plans={item.plans}
                subRouteName={t(item.name)}
                tooltipContent={getTooltipContent('keywords', item.name)}
                selected={exactRoute(item.path)}
                nextAvailablePlan={item?.nextAvailablePlan}
              />
            ))}
          </List>
        </Collapse>

        <ListItem>
          <ListItemText primary={t('routes.domain')} />
        </ListItem>
        <Collapse in>
          <List component="div" disablePadding>
            {ROUTES_DOMAINS.map((item) => (
              <SubRoute
                key={item.key}
                path={item.path}
                plans={item.plans}
                subRouteName={t(item.name)}
                tooltipContent={getTooltipContent('domains', item.name)}
                selected={exactRoute(item.path)}
                nextAvailablePlan={item?.nextAvailablePlan}
              />
            ))}
          </List>
        </Collapse>

        <ListItem>
          <ListItemText primary={t('routes.niche-ideas')} />
        </ListItem>
        <Collapse in>
          <List component="div" disablePadding>
            {ROUTES_NICHE_IDEAS.map((item) => (
              <SubRoute
                key={item.key}
                path={item.path}
                plans={item.plans}
                subRouteName={t(item.name)}
                tooltipContent={getTooltipContent('nicheIdeas', item.name)}
                selected={exactRoute(item.path)}
                nextAvailablePlan={item?.nextAvailablePlan}
              />
            ))}
          </List>
        </Collapse>
      </List>
    </div>
  );
}