import * as ENV from '../lib/envVariables';

// Service Keywords
export const SERVICE_KEYWORDS_GET_KEYWORDS = ENV.VITE_SERVICE_KEYWORDS_GET_KEYWORDS;
export const SERVICE_KEYWORDS_GET_KEYWORD_OVERVIEW = ENV.VITE_SERVICE_KEYWORDS_GET_KEYWORD_OVERVIEW;
export const SERVICE_KEYWORDS_GET_NICHE_IDEAS = ENV.VITE_SERVICE_KEYWORDS_GET_NICHE_IDEAS;
export const SERVICE_KEYWORDS_GET_IN_BULK = ENV.VITE_SERVICE_KEYWORDS_GET_IN_BULK;
export const SERVICE_KEYWORDS_EXPORT_KEYWORDS_IDEAS = ENV.VITE_SERVICE_KEYWORDS_EXPORT_KEYWORDS_IDEAS;
export const SERVICE_KEYWORDS_EXPORT_NICHE_IDEAS = ENV.VITE_SERVICE_KEYWORDS_EXPORT_NICHE_IDEAS;
export const SERVICE_KEYWORDS_EXPORT_BULK_KEYWORDS = ENV.VITE_SERVICE_KEYWORDS_EXPORT_BULK_KEYWORDS;
export const SERVICE_KEYWORDS_TRACE_LOG = ENV.VITE_SERVICE_KEYWORDS_TRACE_LOG;


// Service Domains
export const SERVICE_DOMAINS_BLACKLINKS = ENV.VITE_SERVICE_DOMAINS_BLACKLINKS;
export const SERVICE_DOMAINS_GET_BULK_OVERVIEW = ENV.VITE_SERVICE_DOMAINS_GET_BULK_OVERVIEW;
export const SERVICE_DOMAINS_GET_COMPARISON = ENV.VITE_SERVICE_DOMAINS_GET_COMPARISON;
export const SERVICE_DOMAINS_GET_COMPETITORS = ENV.VITE_SERVICE_DOMAINS_GET_COMPETITORS;
export const SERVICE_DOMAINS_GET_CONTENT_GAP = ENV.VITE_SERVICE_DOMAINS_GET_CONTENT_GAP;
export const SERVICE_DOMAINS_GET_IDEA_OVERVIEW = ENV.VITE_SERVICE_DOMAINS_GET_IDEA_OVERVIEW;
export const SERVICE_DOMAINS_GET_INTENTS = ENV.VITE_SERVICE_DOMAINS_GET_INTENTS;
export const SERVICE_DOMAINS_GET_KEYWORDS = ENV.VITE_SERVICE_DOMAINS_GET_KEYWORDS;
export const SERVICE_DOMAINS_EXPORT_KEYWORDS = ENV.VITE_SERVICE_DOMAINS_EXPORT_KEYWORDS;
export const SERVICE_DOMAINS_GET_RANK_RANGES = ENV.VITE_SERVICE_DOMAINS_GET_RANK_RANGES;
export const SERVICE_DOMAINS_OVERVIEW = ENV.VITE_SERVICE_DOMAINS_OVERVIEW;
export const SERVICE_DOMAINS_OVERVIEW_HISTORY = ENV.VITE_SERVICE_DOMAINS_OVERVIEW_HISTORY;

// Service DomainsV2
export const SERVICE_DOMAINS_V2_PAGES = ENV.VITE_SERVICE_DOMAINS_V2_PAGES;
export const SERVICE_DOMAINS_V2_GET_KEYWORDS = ENV.VITE_SERVICE_DOMAINS_V2_GET_KEYWORDS;
export const SERVICE_DOMAINS_V2_GET_COMPARISON = ENV.VITE_SERVICE_DOMAINS_V2_GET_COMPARISON;
export const SERVICE_DOMAINS_V2_GET_COMPETITORS = ENV.VITE_SERVICE_DOMAINS_V2_GET_COMPETITORS;

// Service trends
export const SERVICE_USERS_GOOGLE_REDIRECT_URI = ENV.VITE_SERVICE_USERS_GOOGLE_REDIRECT_URI;

// Service Users
export const SERVICE_USERS_REDIRECT_STRIPE = ENV.VITE_SERVICE_USERS_REDIRECT_STRIPE;
export const SERVICE_USERS_CREATE_CHECKOUT = ENV.VITE_SERVICE_USERS_CREATE_CHECKOUT;
export const SERVICE_USERS_GET_CONNECTED_ACCOUNTS = ENV.VITE_SERVICE_USERS_GET_CONNECTED_ACCOUNTS;
export const SERVICE_USERS_GET_GOOGLE_TOKEN = ENV.VITE_SERVICE_USERS_GET_GOOGLE_TOKEN;
export const SERVICE_USERS_DELETE_CONNECTED_ACCOUNT = ENV.VITE_SERVICE_USERS_DELETE_CONNECTED_ACCOUNT;
export const SERVICE_USERS_GET_PROPERTIES = ENV.VITE_SERVICE_USERS_GET_PROPERTIES;
export const SERVICE_USERS_CREATE_PROJECT = ENV.VITE_SERVICE_USERS_CREATE_PROJECT;
export const SERVICE_USERS_GET_PROJECTS = ENV.VITE_SERVICE_USERS_GET_PROJECTS;
export const SERVICE_USERS_RETRIEVE_CHECKOUT = ENV.VITE_SERVICE_USERS_RETRIEVE_CHECKOUT;
export const SERVICE_USERS_DELETE_PROJECT = ENV.VITE_SERVICE_USERS_DELETE_PROJECT;
export const SERVICE_USERS_GET_INVOICES = ENV.VITE_SERVICE_USERS_GET_INVOICES;
export const SERVICE_USERS_POST_SAVE_INVOICES = ENV.VITE_SERVICE_USERS_POST_SAVE_INVOICES;


// Service Projects
export const SERVICE_PROJECTS_GET_KEYWORDS_FROM_URL = ENV.VITE_SERVICE_PROJECTS_GET_KEYWORDS_FROM_URL;
export const SERVICE_PROJECTS_GET_CANNIBALIZATION = ENV.VITE_SERVICE_PROJECTS_GET_CANNIBALIZATION;
export const SERVICE_PROJECTS_GET_THIN_CONTENT = ENV.VITE_SERVICE_PROJECTS_GET_THIN_CONTENT;
export const SERVICE_PROJECTS_GET_PROJECTS_PERFORMANCE = ENV.VITE_SERVICE_PROJECTS_GET_PROJECTS_PERFORMANCE;
export const SERVICE_PROJECTS_EXPORT_CANNIBALIZATION = ENV.VITE_SERVICE_PROJECTS_EXPORT_CANNIBALIZATION;
export const SERVICE_PROJECTS_EXPORT_THIN_CONTENT = ENV.VITE_SERVICE_PROJECTS_EXPORT_THIN_CONTENT;
export const SERVICE_PROJECTS_EXPORT_KEYWORDS = ENV.VITE_SERVICE_PROJECTS_EXPORT_KEYWORDS;
export const SERVICE_PROJECTS_GET_PROJECT_INFO = ENV.VITE_SERVICE_PROJECTS_GET_PROJECT_INFO;
