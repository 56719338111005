import './App.scss';
import Routes from './routes';
import { LicenseInfo } from '@mui/x-license';

LicenseInfo.setLicenseKey('c856fbc5a262b5be23c75a52a4e6cc32Tz05MTI4OSxFPTE3NDgxODY1NzkwMDAsUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixLVj0y');

function App() {
  return <Routes />;
}

export default App;