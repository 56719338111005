import React, { useEffect } from 'react';
import { Box } from '@mui/material';
import { useSelectedPlan } from '../../context/PlanContext';
import { ALL_PLANS, PER_YEAR_OR_MONTH } from '../../utils/SubscriptionPlans';
import { useUserContext } from '../../context/UserContext';
import { useUsers } from '../../hooks/useUsers';
import Splash from '../Splash';

interface CheckoutProps {
  plan: string | null;
  billing: string | null;
  coupon: string | null;
}

const Checkout: React.FC<CheckoutProps> = ({ plan, billing, coupon }) => {
  const { selectedPlan, selectedBilling } = useSelectedPlan();
  const { isAuthenticated, loadingPlan } = useUserContext();
  const { error, loadingRedirectStripe, fetchRedirectToCheckoutStripe } = useUsers();

  useEffect(() => {
    const token = localStorage.getItem('authToken');

    const hasMethod = plan && ALL_PLANS.includes(plan);
    const hasBilling = billing && PER_YEAR_OR_MONTH.includes(billing);

    if (token && isAuthenticated && !loadingPlan && hasMethod && hasBilling) {
      fetchRedirectToCheckoutStripe({ token, plan, billing, coupon });
    } else {
      console.error('Error in Checkout useEffect:', { token, isAuthenticated, loadingPlan, plan, billing });
    }
  }, [isAuthenticated, fetchRedirectToCheckoutStripe, loadingPlan, plan, billing]);


  return (
    <Splash />
  );
};

export default Checkout;