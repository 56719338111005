import { useState, useCallback, useMemo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import useExportData from '../../hooks/useExportData';
import { useKeywordsData } from '../../hooks/useKeywordsData';
import * as CONSTANTS from '../../utils/Constants';
import { COLUMNS_NICHE_IDEAS_KEYWORDS } from '../../utils/TableColumnsConstants';
import { KeywordsFiltersProvider, useKeywordsFiltersContext } from '../../context/KeywordsFilterContext';
import { SearchCacheProvider, useSearchCache } from '../../context/SearchCacheContext';
import { KeywordsCacheProvider, useKeywordsCache } from '../../context/KeywordsCacheContext';

import { Grid, Typography } from '@mui/material';
import Search from '../../components/Search';
import SearchSimple from '../../components/SearchSimple';
import PaperItem from '../../components/Essential/PaperItem';
import TableComponent from '../../components/TableComponent';
import ButtonExportExcel from '../../components/Buttons/ExportExcel';
import CustomTooltip from '../../components/CustomTooltip/CustomTooltip';
import KeywordsFilters from '../../components/Filters/Keywords/KeywordsFilter';
import useRefreshQueries from '../../hooks/useRefreshQueries';
import { useKeywordsIdeasCache } from '../../context/KeywordsIdeasCacheContext';

const NICHE_IDEAS_PAGE_CACHE_CONTEXT = 'nicheIdeasPage';
const NICHE_IDEAS_SEARCH_CACHE_CONTEXT = 'nicheIdeasSearch';
const FILTERS_STORAGE_KEY = 'niche_ideas_filters';

const ContainerPage = () => {
  const [keyword, setKeyword] = useState<string>('');
  const [fetchedKeywords, setFetchedKeywords] = useState<string[]>([]);

  const { location, handleLocation } = useRefreshQueries(NICHE_IDEAS_PAGE_CACHE_CONTEXT);
  const { updateLocation } = useKeywordsIdeasCache();

  const [searching, setSearching] = useState<boolean>(false);

  const { t } = useTranslation();
  const { isLoading, fetchExportKeywordsNicheIdeas } = useExportData();
  const { nicheIdeas, loadingNicheIdeas, fetchNicheIdeas } = useKeywordsData(NICHE_IDEAS_PAGE_CACHE_CONTEXT);
  const {
    numOfKeywords,
    handleSearchKeywords,
    handleNumOfKeywords
  } = useKeywordsFiltersContext();

  const { getSearchCache, updateSearchCache } = useSearchCache();
  const { getCache } = useKeywordsCache();

  const initialSearchQuery = getSearchCache(NICHE_IDEAS_SEARCH_CACHE_CONTEXT) || '';

  useEffect(() => {
    if (initialSearchQuery) {
      setKeyword(initialSearchQuery);
    }

    const cachedData = getCache(NICHE_IDEAS_PAGE_CACHE_CONTEXT);

    if (cachedData?.nicheIdeas?.items) {
      const keywords = cachedData.nicheIdeas.items.map(item => item.keyword).filter(kw => typeof kw === 'string');
      setFetchedKeywords(keywords);
    }
  }, [initialSearchQuery, getCache]);

  const requestMemoize = useMemo(
    () => ({ location, keywords: keyword, limit: CONSTANTS.PAGE_SIZE, ...handleSearchKeywords() }),
    [keyword, location, handleSearchKeywords]
  );

  const refreshData = useCallback(
    async (kw: string) => {
      try {
        const response = await fetchNicheIdeas({ ...requestMemoize, keywords: kw });

        if (response && response.items) {
          const keywords = response.items
            .map(item => item.keyword)
            .filter((kw): kw is string => typeof kw === 'string');
          setFetchedKeywords(keywords);
        }
      } catch (error) {
        console.error('Error fetching niche ideas:', error);
      }
    },
    [requestMemoize, fetchNicheIdeas]
  );

  const handleSearchBtn = async (search: string) => {
    setSearching(true);
    setKeyword(search);
    updateSearchCache(NICHE_IDEAS_SEARCH_CACHE_CONTEXT, search);
    await refreshData(search);
    setSearching(false);
  };

  const handleKeyPress = (event: KeyboardEvent) => {
    if (event.key === CONSTANTS.KEY_ENTER_NAME && event.ctrlKey && !loadingNicheIdeas) {
      handleSearchBtn('');
    }
  };

  const handleExportBtn = async () => {
    const cachedData = getCache(NICHE_IDEAS_PAGE_CACHE_CONTEXT);
    
    const keywordsToExport = fetchedKeywords.length 
      ? fetchedKeywords 
      : (cachedData?.nicheIdeas?.items ?? []).map(item => item.keyword).filter(kw => typeof kw === 'string');

    if (!keywordsToExport || !Array.isArray(keywordsToExport) || keywordsToExport.length === 0) {
      return;
    }

    const data = {
      limit: CONSTANTS.PAGE_SIZE,
      keywords: keywordsToExport,
      location,
      title: 'Ideas de nicho'
    };

    await fetchExportKeywordsNicheIdeas(data);
  };

  useEffect(() => {
    const listener = (event: KeyboardEvent) => handleKeyPress(event);
    document.addEventListener('keydown', listener);
    return () => {
      document.removeEventListener('keydown', listener);
    };
  }, [loadingNicheIdeas]);

  return (
    <Grid container spacing={2} className="container-analysis">
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <PaperItem className="flex-col gap-2 boxShadow" sx={{ p: 3 }}>
          <Search
            cacheKey={NICHE_IDEAS_SEARCH_CACHE_CONTEXT}
            placeholder={t('search_placeholder_keyword_ideas')}
            onSearch={handleSearchBtn}
            loading={loadingNicheIdeas}
            onCountry={(code: number) => {
              handleLocation(code);
              updateLocation(NICHE_IDEAS_SEARCH_CACHE_CONTEXT, code);
            }}
            allowEmptySearches={true}
          />
          <div className="flex gap-2 w-full items-center">
            <SearchSimple
              type="number"
              defaultValue={String(numOfKeywords)}
              placeholder={t('button.num_of_keywords')}
              onChange={(e) => handleNumOfKeywords(Number(e))}
              style={{ padding: '1px', borderRadius: '8px' }}
            />
            <KeywordsFilters />
          </div>
        </PaperItem>
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <PaperItem className="boxShadow" sx={{ p: 3 }}>
          <div className="flex items-center justify-between">
            <CustomTooltip content={t('tooltips.keywords.niche_ideas_title')}>
              <Typography className="mb-5">{t('routes.niche-ideas')}</Typography>
            </CustomTooltip>
            <ButtonExportExcel
              isLoading={isLoading}
              onClick={handleExportBtn}
              totalKeywords={nicheIdeas.items.length}
              query={keyword || getSearchCache(NICHE_IDEAS_SEARCH_CACHE_CONTEXT)}
            />
          </div>
          <TableComponent
            hideFooter
            rows={nicheIdeas.items}
            loading={loadingNicheIdeas}
            columns={COLUMNS_NICHE_IDEAS_KEYWORDS(t, { code: location })}
            paginationMode="client"
            sortingMode="client"
          />
        </PaperItem>
      </Grid>
    </Grid>
  );
};

export const NicheIdeasPage = () => (
  <KeywordsFiltersProvider storageKey={FILTERS_STORAGE_KEY}>
    <SearchCacheProvider>
      <KeywordsCacheProvider>
        <ContainerPage />
      </KeywordsCacheProvider>
    </SearchCacheProvider>
  </KeywordsFiltersProvider>
)