import React from 'react';
import useRedirect from '../../hooks/useRedirect';
import { BASE_URL, NOREFERRER, ROUTES } from '../../utils/Constants';
import { useUserContext } from '../../context/UserContext';
import Splash from '../Splash';

/**
 * PlanGuard component.
 *
 * This component is responsible for guarding the routes based on the user's product and authentication status.
 * If the user is authenticated but does not have a product, it will navigate to the subscription info page.
 *
 * @param {React.PropsWithChildren} children - The child components to render.
 * @returns {React.ReactNode} - The rendered child components.
 */
const PlanGuard = ({ children }: React.PropsWithChildren): React.ReactNode => {
  const [redirect, setRedirect] = React.useState<boolean>(false);
  const { plan, loadingPlan, isAuthenticated } = useUserContext();

  useRedirect();

  const isBuyPlanRoute = () => /^\/buy-plan.*$/i.test(window.location.pathname);

  React.useEffect(() => {
    const hasRedirected = sessionStorage.getItem('hasRedirected');

    if (!plan && !loadingPlan && isAuthenticated && !hasRedirected) {
      setRedirect(true);
      sessionStorage.setItem('hasRedirected', 'true');
      if (isBuyPlanRoute()) {
        window.open(window.location.href, '_self', NOREFERRER);
      } else {
        window.open(ROUTES.BUY_PLAN_USER, '_self', NOREFERRER);
      }
    }
    if (!isBuyPlanRoute() && !plan && !loadingPlan && isAuthenticated) {
      window.open(ROUTES.BUY_PLAN_USER, '_self', NOREFERRER);
    }
  }, [plan, loadingPlan, isAuthenticated]);

  if (redirect) return <Splash />;
  return children;
};

export default PlanGuard;