import { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import axios from 'axios';
import { Box, Paper, LinearProgress, Grid, Typography, Card, CardContent, IconButton, Alert, Tooltip } from '@mui/material';
import { PieChart, Pie, Cell, ResponsiveContainer } from 'recharts';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import styles from './PageSpeedInsights.module.scss';

interface PageSpeedResult {
  fcp: string;
  lcp: string;
  si: string;
  tbt: string;
  cls: string;
  score: number;
}

const PageSpeedInsights: React.FC<{ url: string }> = ({ url }) => {
  const { t } = useTranslation();

  const [data, setData] = useState<{ desktop: PageSpeedResult | null, mobile: PageSpeedResult | null }>({ desktop: null, mobile: null });
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>("");
  const [progress, setProgress] = useState<number>(0);
  const [isCollapsible, setIsCollapsible] = useState<boolean>(false);
  const [showIconButton, setShowIconButton] = useState<boolean>(false);

  const cleanDomain = (domain: string) => {
    let cleanedDomain = domain.replace(/^sc-domain:/i, '').trim();
    if (!/^https?:\/\//i.test(cleanedDomain)) {
      cleanedDomain = `https://${cleanedDomain}`;
    }
    return cleanedDomain;
  };

  const fetchData = async (strategy: string, currentUrl: string) => {
    const response = await axios.get(
      `https://www.googleapis.com/pagespeedonline/v5/runPagespeed?url=${encodeURIComponent(currentUrl)}&strategy=${strategy}`
    );
    return {
      fcp: response.data.lighthouseResult.audits['first-contentful-paint'].displayValue,
      lcp: response.data.lighthouseResult.audits['largest-contentful-paint'].displayValue,
      si: response.data.lighthouseResult.audits['speed-index'].displayValue,
      tbt: response.data.lighthouseResult.audits['total-blocking-time'].displayValue,
      cls: response.data.lighthouseResult.audits['cumulative-layout-shift'].displayValue,
      score: response.data.lighthouseResult.categories.performance.score * 100,
    };
  };

  const fetchPageSpeedData = useCallback(async () => {
    let cleanedUrl = cleanDomain(url);
    if (!cleanedUrl) {
      setError("El dominio no es válido. Por favor, introduce un dominio válido.");
      return;
    }
    setLoading(true);
    setError("");
    setData({ desktop: null, mobile: null });
    setShowIconButton(false);
    try {
      const [desktopData, mobileData] = await Promise.all([fetchData('desktop', cleanedUrl), fetchData('mobile', cleanedUrl)]);
      setData({ desktop: desktopData, mobile: mobileData });
    } catch (initialError) {
      const fallbackUrl = cleanedUrl.replace(/^https:\/\//, 'http://');
      try {
        const [desktopData, mobileData] = await Promise.all([fetchData('desktop', fallbackUrl), fetchData('mobile', fallbackUrl)]);
        setData({ desktop: desktopData, mobile: mobileData });
      } catch (fallbackError) {
        setError(t('error_get_cwv'));
      }
    } finally {
      setLoading(false);
      setShowIconButton(true);
    }
  }, [url]);

  useEffect(() => {
    let interval: NodeJS.Timeout | null = null;
    if (loading) {
      interval = setInterval(() => {
        setProgress((oldProgress) => {
          if (oldProgress === 100) return 100;
          const diff = Math.random() * 5;
          return Math.min(oldProgress + diff, 100);
        });
      }, 1000);
    } else {
      clearInterval(interval!);
      setProgress(100);
      setTimeout(() => setProgress(0), 500);
    }
    return () => clearInterval(interval!);
  }, [loading]);

  useEffect(() => {
    if (url) {
      fetchPageSpeedData().catch(console.error);
    }
  }, [url, fetchPageSpeedData]);

  return (
    <Paper className={styles.container} elevation={3}>
      {loading && (
        <>
          <LinearProgress variant="determinate" value={progress} className={styles.loader} />
          <p>Cargando Core Web Vitals...</p>
        </>
      )}
      <Box className={styles.header}>
        {showIconButton && (
          <>
            <Typography variant="h6" className={styles.headerTitle}>Core Web Vitals</Typography>
            <Tooltip title={isCollapsible ? "Ocultar" : "Mostrar"}>
              <IconButton onClick={() => setIsCollapsible(prev => !prev)} disabled={loading} className={styles.iconButton} >
                {isCollapsible ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                <Typography variant="body2" className={styles.iconButtonText}>{isCollapsible ? "Ocultar" : "Mostrar"}</Typography>
              </IconButton>
            </Tooltip>
          </>
        )}
      </Box>
      {error && <Alert severity="error">{error}</Alert>}
      <Box className={`${styles.content} ${isCollapsible ? styles.expandWithScroll : styles.collapsibleContent}`}>
        {data.desktop && data.mobile && (
          <Grid container spacing={4}>
            <Grid item xs={12} md={6}>
              <Typography variant="h6" className={styles.subTitle}>Mobile</Typography>
              <ReportCard data={data.mobile} />
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant="h6" className={styles.subTitle}>Desktop</Typography>
              <ReportCard data={data.desktop} />
            </Grid>
          </Grid>
        )}
      </Box>
    </Paper>
  );
};

const getColor = (value: number, type: string) => {
  switch (type) {
    case 'score':
      return value > 90 ? '#4caf50' : value > 50 ? '#ffeb3b' : '#f44336';
    case 'si':
      return value < 3.4 ? '#4caf50' : value < 5.8 ? '#ffeb3b' : '#f44336';
    case 'tbt':
      return value < 200 ? '#4caf50' : value < 600 ? '#ffeb3b' : '#f44336';
    case 'cls':
      return value < 0.1 ? '#4caf50' : value < 0.25 ? '#ffeb3b' : '#f44336';
    default:
      return value < 2.5 ? '#4caf50' : value < 4 ? '#ffeb3b' : '#f44336';
  }
};

const ReportCard: React.FC<{ data: PageSpeedResult }> = ({ data }) => {

  const pieData = [
    { name: 'score', value: data.score },
    { name: 'remainder', value: 100 - data.score }
  ];

  return (
    <Box mt={2}>
      <Box className={styles.scoreContainer}>
        <ResponsiveContainer width="100%" height={200}>
          <PieChart>
            <Pie data={pieData} dataKey="value" startAngle={90} endAngle={-270} innerRadius={70} outerRadius={80} labelLine={false} >
              {pieData.map((entry, index) => (
                <Cell key={index} fill={entry.name === 'score' ? getColor(entry.value, 'score') : '#e0e0e0'} />
              ))}
            </Pie>
            <text x="50%" y="50%" textAnchor="middle" dominantBaseline="middle" fill={getColor(data.score, 'score')} fontSize={24}>
              {data.score}
            </text>
          </PieChart>
        </ResponsiveContainer>
        <Typography variant="h6" className={styles.performanceText}> Performance </Typography>
      </Box>
      <Grid container spacing={2} className={styles.resultsGrid}>
        {data && Object.entries({
          fcp: 'First Contentful Paint (FCP)',
          lcp: 'Largest Contentful Paint (LCP)',
          si: 'Speed Index (SI)',
          tbt: 'Total Blocking Time (TBT)',
          cls: 'Cumulative Layout Shift (CLS)',
        }).map(([key, label]) => (
          <Grid item xs={12} sm={12} md={6} key={key}>
            <Card>
              <CardContent>
                <Box className={styles.cardHeader}>
                  <span className={styles.indicator} style={{ backgroundColor: getColor(parseFloat(data[key as keyof PageSpeedResult]), key) }}></span>
                  <Typography variant="body2" color="textSecondary">{label}</Typography>
                </Box>
                <Typography variant="h5" component="p" className={styles.cardValue}>
                  {data[key as keyof PageSpeedResult]}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default PageSpeedInsights;